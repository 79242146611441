<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="monthCard-edit">
            <el-form :model="form" ref="form" :rules="rules" label-width="120px" label-position="left">
                <div class="a-fs-16 a-fw-700 a-flex-rfsc" style="margin-bottom: 16px">
                    <span>基本信息</span>
                </div>
                <el-form-item label="优惠券名称" prop="name">
                    <el-input class="inputBox" maxlength="12" clearable v-model="form.name" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item label="优惠券类型" prop="couponType">
                    <el-radio-group v-model="form.couponType" @change='couponTypeChange'>
                        <el-radio 
                            v-for="(item,index) in couponTypeDic"
                            :key="index"
                            :label="item.value"
                            class="a-flex-rfsc a-mb-10">
                            <div class="a-flex-rfsc">
                                <span>{{ item.text }}，</span>
                                <!-- 折扣券 -->
                                <div class="a-flex-rfsc" v-if="item.value == 1">
                                    <span>购买时打</span>
                                    <el-input 
                                        :disabled='form.couponType != 1'
                                        style="width:200px;margin: 0 10px;"
                                        clearable 
                                        v-model="item.discount" 
                                        placeholder="请输入" 
                                        @input="item.discount=util.checkMoney1(item.discount)"></el-input>
                                    <span>折，满</span>
                                    <jd-input-price 
                                        :disabled='form.couponType != 1'
                                        v-model="item.limitMoney" 
                                        placeholder="请输入金额"
                                        style="width:200px;margin: 0 10px;"></jd-input-price>
                                    <span>元可用</span>
                                </div>
                                <!-- 抵扣券 -->
                                <div class="a-flex-rfsc" v-if="item.value == 2">
                                    <span>购买时抵扣</span>
                                    <jd-input-price 
                                        :disabled='form.couponType != 2'
                                        v-model="item.discount" 
                                        placeholder="请输入金额"
                                        style="width:200px;margin: 0 10px;"></jd-input-price>
                                    <span>元，满</span>
                                    <jd-input-price 
                                        :disabled='true'
                                        v-model="item.limitMoney" 
                                        placeholder="请输入金额"
                                        style="width:200px;margin: 0 10px;"></jd-input-price>
                                    <span>元可用</span>
                                </div>
                                <!-- 免单券 -->
                                <div class="a-flex-rfsc" v-if="item.value == 3">
                                    <span>订单金额不超过</span>
                                    <jd-input-price 
                                        :disabled='form.couponType != 3'
                                        v-model="item.limitMoney" 
                                        placeholder="请输入金额"
                                        style="width:200px;margin: 0 10px;"></jd-input-price>
                                    <span>元可用</span>
                                </div>
                            </div>
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="优惠券有效期" prop="timeType">
                    <div class="a-flex-rfsc">
                        <el-radio-group class="a-flex-cfsfs" v-model="form.timeType" @change='timeTypeChange'>
                            <el-radio 
                                class="a-flex-rfsc a-mt-10"
                                v-for="(item,index) in timeTypeDic"
                                :key="index"
                                :label="item.value" >
                                <div class="a-flex-rfsc">
                                    {{ item.text }}
                                    <div class="a-flex-rfsc" v-if="item.value == 2">
                                        <span>，有效期</span>
                                        <el-input 
                                            style="width:120px;margin: 0 10px;" 
                                            clearable 
                                            maxlength='4'
                                            v-model="form.day" 
                                            placeholder="请输入次数" 
                                            @input="form.day=form.day.replace(/^(0+)|[^\d]+/g,'')"></el-input>
                                        <span>天</span>
                                    </div>
                                    <div class="a-flex-rfsc" v-if="item.value == 3">
                                        <span>，日期</span>
                                        <jd-datetime-range 
                                            :large="true" 
                                            style="margin:0;padding: 0;margin-left: 10px;" 
                                            label="" 
                                            type="daterange"
                                            valueFormat="yyyy-MM-dd" 
                                            :minDate.sync="form.startTime" 
                                            :maxDate.sync="form.endTime" />
                                    </div>
                                </div>
                                
                            </el-radio>
                        </el-radio-group>
                        
                    </div>
                </el-form-item>
                <el-form-item 
                    label="影响范围" 
                    prop="joinScope" >
                    <div class="a-flex-rfsc">
                        <el-select style="width: 190px" v-model="form.joinScope" placeholder="请选择">
                            <el-option label="全部" :value="1"></el-option>
                            <el-option label="指定范围" :value="2"></el-option>
                        </el-select>
                        <el-select style="width: 225px;margin-left: 10px" v-if="form.joinScope == 2" v-model="form.joinScopeSecond" multiple placeholder="请选择指定范围（可多选）">
                            <el-option label="商户" :value="2"></el-option>
                            <el-option label="项目" :value="3"></el-option>
                            <el-option label="站点" :value="4"></el-option>
                        </el-select>
                    </div>
                    
                    <le-company-under-select 
                        v-if="form.joinScopeSecond.indexOf(2) != -1 && form.joinScope == 2"
                        label="商户"
                        placeholder="请选择商户（可多选）"
                        style="width:425px"
                        reserveKeyword 
                        :collapse='false'
                        multiple 
                        large
                        isObj
                        v-model="companyIds">
                    </le-company-under-select>
                    <span class="a-fs-12 a-c-333 a-fw-700" v-if="form.joinScopeSecond.indexOf(2) != -1 && form.joinScope == 2">共选择 {{ companyIds.length }} 商户</span>
                    <le-select-remote-search 
                        v-if="form.joinScopeSecond.indexOf(3) != -1 && form.joinScope == 2"
                        label="项目"
                        style="width:425px" 
                        reserveKeyword 
                        multiple 
                        large
                        isObj
                        :collapse='false'
                        v-model="incomeIds" 
                        :options="optionsincomeDistri" 
                        placeholder="请选择项目（可多选）" />
                    <span class="a-fs-12 a-c-333 a-fw-700" v-if="form.joinScopeSecond.indexOf(3) != -1 && form.joinScope == 2">共选择 {{ incomeIds.length }} 个项目</span>
                    <le-select-remote-search 
                        v-if="form.joinScopeSecond.indexOf(4) != -1 && form.joinScope == 2"
                        label="站点"
                        style="width:425px"
                        reserveKeyword 
                        multiple 
                        large
                        isObj
                        selectAll
                        :collapse='false'
                        v-model="stationIds" 
                        :options="optionsStation" placeholder="请选择站点（可多选）" />
                    <span class="a-fs-12 a-c-333 a-fw-700" v-if="form.joinScopeSecond.indexOf(4) != -1 && form.joinScope == 2">共选择 {{ stationIds.length }} 个站点</span>
                </el-form-item>
                <el-form-item label="是否参与分账" prop="needProfit">
                    <el-radio-group v-model="form.needProfit">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input
                        type="textarea"
                        :rows="4"
                        maxlength="80"
                        class="inputBox"
                        placeholder="请输入内容"
                        v-model="form.remark">
                    </el-input>
                </el-form-item>
            </el-form>
            <div style="height: 100px;"></div>
            <div class="a-line-t-e0 footerBox">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
                <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancelSubmit">&nbsp;&nbsp;&nbsp;返回&nbsp;&nbsp;&nbsp;</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import util from '../../../src/utils/util'
export default {
    data() {
        var checkCouponType = (rule, value, callback) => {
            if(!this.form.couponType){
                callback(new Error('请选择优惠券类型'))
            }else{
                let coupon = this.couponTypeDic.find(res=>{
                    return res.value == this.form.couponType
                })

                if(this.form.couponType == 1) {
                    if(!coupon.discount) {
                        callback(new Error('请输入优惠券折扣'))
                    }else if(coupon.limitMoney === '') {
                        callback(new Error('请输入优惠券使用条件'))
                    }else {
                        callback()
                    }
                }else if(this.form.couponType == 2) {
                    if(!coupon.discount) {
                        callback(new Error('请输入优惠券抵扣金额'))
                    }else if(coupon.limitMoney === '') {
                        callback(new Error('请输入优惠券使用条件'))
                    }else {
                        callback()
                    }
                }else if(this.form.couponType == 3) {
                    if(coupon.limitMoney === '') {
                        callback(new Error('请输入优惠券使用条件'))
                    }else {
                        callback()
                    }
                }else {
                    callback()
                }
            }
        };
        var checkTimeType = (rule, value, callback) => {
            if(!this.form.timeType){
                callback(new Error('请选择优惠券有效期'))
            }else{
                if(this.form.timeType == 2) {
                    if(!this.form.day) {
                        callback(new Error('请输入优惠券有效天数'))
                    }else {
                        callback()
                    }
                }else if(this.form.timeType == 3) {
                    if(!this.form.startTime) {
                        callback(new Error('请选择优惠券有效期'))
                    }else {
                        callback()
                    }
                }else {
                    callback()
                }
            }
        };
        var checkJoinScope = (rule, value, callback) => {
            if(!this.form.joinScope || (!this.form.joinScopeSecond.length && this.form.joinScope == 2)){
                callback(new Error('请选择影响范围'))
            }else{
                if(this.form.joinScopeSecond.indexOf(2) != -1 && !this.companyIds.length) {
                    callback(new Error('请选择影响商户'))
                }else if(this.form.joinScopeSecond.indexOf(3) != -1 && !this.incomeIds.length) {
                    callback(new Error('请选择影响项目'))
                }else if(this.form.joinScopeSecond.indexOf(4) != -1 && !this.stationIds.length) {
                    callback(new Error('请选择影响站点'))
                }else {
                    callback()
                }
            }
        };
        return {
            id: '',
            util: util,
            form:{
                name: '',
                couponType: '',
                discount: '',//优惠券价值
                limitMoney: '',//优惠券门槛
                timeType: '',//
                day: '',
                startTime: '',
                endTime: '',
                remark: '',
                joinScope: '',
                joinScopeSecond: [],
                scopeValidList: [],
                needProfit: null
            },
            rules:{
                name: [{required: true, message:'请输入优惠券名称', trigger: 'blur'}],
                couponType: [{ validator: checkCouponType, trigger: 'change' }],
                timeType: [{ validator: checkTimeType, trigger: 'change' }],//
                joinScope: [{ validator: checkJoinScope, trigger: 'change' }],
                needProfit: [{ required: true, message:'请选择是否参与分账', trigger: 'change' }],
            },
            optionsCoupon: {
                url: this.$Config.apiUrl.couponPage,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "name",
                pageSize: 1000
            },
            optionsincomeDistri: {
                url: this.$Config.apiUrl.incomeDistriList,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "name",
            },//项目数据
            optionsStation: {
                url: this.$Config.apiUrl.getStationList,
                method: "post",
                params: {
                    status: 2
                },
                showLoading: false,
                labelKey: "name",
                valueKey: "stationId",
                searchKey: "nameOrId",
            },//站点数据
            couponTypeDic: [],
            timeTypeDic: [],
            companyIds: [],//优惠券影响范围商户
            incomeIds: [],//优惠券影响范围项目
            stationIds: [],//优惠券影响范围站点
        };
    },
    created () {
        this.$getDic('couponType').then(res=>{ this.couponTypeDic = res; })
        this.$getDic('couponTimeType').then(res=>{ this.timeTypeDic = res; })
    },
    mounted () {
        this.id = this.$route.query.id?this.$route.query.id:''
        if(this.id) {
            this.getCouponInfo()
        }
    },
    methods: {
        getCouponInfo () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getCouponDetail,
                method: "post",
                params: {
                    id: this.id
                }
            }).then(res => {
                if (res.result.code == 0) {
                    this.form = {
                        ...res.result.data,
                        couponType: JSON.stringify(res.result.data.couponType),
                        timeType: JSON.stringify(res.result.data.timeType),
                        startTime: res.result.data.startTime ? this.$getDay.getTimeStampToStr(res.result.data.startTime,'YYYY-MM-DD') : '',
                        endTime: res.result.data.endTime ? this.$getDay.getTimeStampToStr(res.result.data.endTime,'YYYY-MM-DD') : '',
                    }
                    let coupon = {
                        limitMoney: (this.form.limitMoney/100).toFixed(2),
                        discount: this.form.couponType == 2 ? (this.form.discount/100).toFixed(2) : this.form.discount
                    }
                    this.couponTypeDic = this.couponTypeDic.map(item=>{
                        if(item.value == this.form.couponType) {
                            item = {
                                ...item,
                                ...coupon
                            }
                        }
                        return {
                            ...item
                        }
                    })

                    this.$set(this.form,'joinScopeSecond',[])
                    this.form.scopeValidList.map(item=>{
                        if(item.scopeType == 1) {
                            this.companyIds.push({
                                label: item.name,
                                value: item.scopeId
                            })
                        }
                        if(item.scopeType == 2) {
                            this.incomeIds.push({
                                label: item.name,
                                value: item.scopeId
                            })
                        }
                        if(item.scopeType == 3) {
                            this.stationIds.push({
                                label: item.name,
                                value: item.scopeId
                            })
                        }
                    })
                    if(this.companyIds.length) {
                        this.form.joinScopeSecond.push(2)
                    }
                    if(this.incomeIds.length) {
                        this.form.joinScopeSecond.push(3)
                    }
                    if(this.stationIds.length) {
                        this.form.joinScopeSecond.push(4)
                    }
                } else {
                    this.$message.error(res.result.message)
                }
            })
        },
        submit () {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.$confirm('是否确认提交？','温馨提示')
                    .then(_=>{
                        let coupon = this.couponTypeDic.find(res=>{
                            return res.value == this.form.couponType
                        })
                        let reqData = {
                            id: this.id,
                            ...this.form,
                            limitMoney: (coupon.limitMoney*100).toFixed(0),
                            discount: this.form.couponType == 2 ? (coupon.discount*100).toFixed(0) : coupon.discount
                        }
                        // 初始化影响范围
                        if(this.form.joinScope == 2) {
                            let companyIds = this.companyIds.map(item=>{
                                return {
                                    ...item,
                                    name: item.label,
                                    scopeId: item.value,
                                    scopeType: 1
                                }
                            })

                            let incomeIds = this.incomeIds.map(item=>{
                                return {
                                    ...item,
                                    name: item.label,
                                    scopeId: item.value,
                                    scopeType: 2
                                }
                            })

                            let stationIds = this.stationIds.map(item=>{
                                return {
                                    ...item,
                                    name: item.label,
                                    scopeId: item.value,
                                    scopeType: 3
                                }
                            })

                            let scopeValidList = []
                            if(this.form.joinScopeSecond.indexOf(2) != -1) {
                                scopeValidList = companyIds
                            }

                            if(this.form.joinScopeSecond.indexOf(3) != -1) {
                                scopeValidList = scopeValidList.concat(incomeIds)
                            }

                            if(this.form.joinScopeSecond.indexOf(4) != -1) {
                                scopeValidList = scopeValidList.concat(stationIds)
                            }

                            // let scopeValidList = companyIds.concat(incomeIds.concat(stationIds))
                            reqData.scopeValidList = scopeValidList
                        }else {
                            reqData.scopeValidList = []
                        }
                        this.$Axios._post({
                            url: this.$Config.apiUrl.couponAddUpdate,
                            method: "post",
                            params: reqData
                        })
                        .then(res=>{
                            if (res.result.code == 0) {
                                this.$message.success('操作成功')
                                this.$router.back()
                                this.$store.dispatch("delView", this.$route);
                            } else {
                                this.$message.error(res.result.message)
                            }
                        })
                        .catch(err=>{
                            console.log(err);
                        })
                        
                    })
                    .catch(err=>{
                        console.log(err);
                    })
                    
                }
            })
        },
        timeTypeChange () {
            this.form.day = ''
            this.form.startTime = ''
            this.form.endTime = ''
        },
        couponTypeChange () {
            this.couponTypeDic = this.couponTypeDic.map(item=>{
                return {
                    ...item,
                    discount: '',
                    limitMoney: item.value == 2 ? '0' : ''
                }
            })
        },
        cancelSubmit () {
            this.$confirm('是否确认返回？','温馨提示')
            .then(_=>{
                this.$router.back()
                this.$store.dispatch("delView", this.$route);
            })
            .catch(_=>{})
        }
    },
}
</script>

<style lang="scss" scoped>
.monthCard-edit{
    font-size: 14px;
    height: 100%;
    overflow-y: auto;
    /deep/ .s-search-label {
		width: 145px !important;
		justify-content: flex-start;
	}
    /deep/ .s-nomarl-input .el-input__inner {
		width: 200px;
	}
    /deep/ .date-range{
        margin: 0;
    }
    /deep/ .projectBox{
        &>div{
            margin: 0;
        }
    }
}
.inputBox{
    width: 400px;
}
.addBankBtn{
    padding: 0 20px;
    font-size: 14px;
    color: #007AFF;
    line-height: 34px;
    cursor: pointer;
}
.a-mb-10{
    margin-bottom: 10px;
}
</style>